import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';

import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import { userPermissions } from '@store/atoms/userPermissions';

import { Permissions } from './types';

export const useGetPermissions = ({
  enabled = true,
}: { enabled?: boolean } = {}) => {
  const client = createHttpClient();

  const setUserPermissions = useSetRecoilState(userPermissions);

  const query = useQuery(
    QUERY_KEYS.USER.PERMISSIONS,
    () => {
      return client<Permissions>({
        url: END_POINTS.USER.PERMISSIONS,
      });
    },
    {
      enabled,
      onSuccess: data => {
        setUserPermissions(data.permissions);
      },
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to fetch user permissions',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return query;
};
